import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Desc from '../Components/Desc'
import Button from '../Components/Button'
import JobsCard from '../Components/JobsCard'
import { ReactComponent as Callagent } from '../Assets/CallagentIcon.svg'
import { ReactComponent as Qualityagent } from '../Assets/QualityagentIcon.svg'
import { ReactComponent as Confirmationagent } from '../Assets/ConfirmationagentIcon.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'

const T = styled.p`
    color:#fff;
    font-size:32px;
    line-height:57.82px;
    font-weight:700;
    margin-top:0px;

    @media (max-width: 899.98px) {
        font-size:18px;
        line-height:18px;
    }

    @media (max-width: 599.98px) { 
        font-size:18px;
        line-height:18px;
    }
`

const Title = styled.div`
    display:block;
    width:100%;
    color:#fff;
    font-size:47.47px;
    line-height:60px;
    font-weight:400;

    @media (max-width: 899.98px) {
        font-size:26px;
        line-height:35px;
    }

    @media (max-width: 599.98px) { 
        font-size:26px;
        line-height:35px;
    }
`

const GreenText = styled.span`
    color:#3162AC;
    font-size:47.47px;
    line-height:60px;
    font-weight:700;

    @media (max-width: 899.98px) {
        font-size:26px;
        line-height:35px;
    }

    @media (max-width: 599.98px) { 
        font-size:26px;
        line-height:35px;
    }
`

// const Wrapper = styled.div`
//     // transition: padding-left 0.3s ease;

//     // &:hover {
//     //     padding-left: 150px;
//     // }

    
//     // @media (max-width: 899.98px) {
//     //     &:hover {
//     //         padding-left: initial;
//     //     }
//     // }

//     // @media (max-width: 599.98px) { 
//     //     &:hover {
//     //         padding-left: initial;
//     //     }
//     // }
// `

const SectionJobs = () => {

    const { width } = useWindowDimensions();
    return (
        <SectionBreaker>
            <div id='vende-te-lira-pune' style={{ backgroundImage: `url(${require('../Assets/bg.jpeg')})`, backgroundSize: 'cover', paddingInline: width < 899.99 && "25px", paddingTop: width > 899.99 ? "200px" : "35px", paddingBottom: width > 899.99 ? "200px" : "35px" }}>
                <Container>
                    <Grid container justifyContent={'space-between'} alignItems={'center'} gap={5}>
                        <Grid item xs={12} md={5} lg={6}>
                            <Grid container flexDirection={'column'}>
                                <Grid item>
                                    <T>Vende të lira pune</T>
                                </Grid>
                                <Title>Dëshiron të <GreenText>sfidosh veten</GreenText> dhe të <GreenText>avancosh</GreenText> në karrierë?</Title>
                                <Grid item>
                                    <Desc style={{ color: "#fff", fontSize: width < 899.99 && "12px", maxWidth: "none" }} desc={'Dërgo aplikimin tënd dhe bëhu pjesë e kompanisë tonë.'} />
                                </Grid>
                                <Grid item>
                                    <Button style={{maxWidth: width < 899.99 && "none"}} button={'Shiko të gjitha pozitat e hapura'} color={'#fff'} />
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={5}>
                            <Grid container flexDirection={'column'} rowSpacing={3} columnSpacing={0} width={'fit-content'}>
                                <Grid item xs={'auto'}>
                                    <JobsCard svg={<Callagent style={{ width: "100%", height: "auto", maxWidth: width < 899.99 && "50px" }} />} job={'Call Agent'} desc={'Apliko Tani'} />
                                </Grid>
                                <Grid item xs={'auto'} marginLeft={{xs: '0px', sm: '110px', md: '30px', lg: '100px'}}>
                                    <JobsCard svg={<Qualityagent style={{ width: "100%", height: "auto", maxWidth: width < 899.99 && "50px" }} />} job={'Quality Agent'} desc={'Apliko Tani'} />
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <JobsCard svg={<Confirmationagent style={{ width: "100%", height: "auto", maxWidth: width < 899.99 && "50px" }} />} job={'Confirmation Agent'} desc={'Apliko Tani'} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </SectionBreaker>
    )
}

export default SectionJobs