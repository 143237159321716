import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const TitleText = styled.span`
    display:block;
    padding-bottom:10px;
    color:#fff;
    font-weight:800;
    font-size:22px;
    line-height:33px;

    @media (max-width: 1440px) {
        font-size:15px;
    }

    @media (max-width: 899.98px) {
        font-size:11px;
    }

    @media (max-width: 599.98px) { 
        font-size:11px;
    }
`

const ListText = styled.span`
    display:block;
    color:#fff;
    font-weight:500;
    font-size:16px;
    line-height:24px;
    width:100%;
    max-width:500px;

    @media (max-width: 1440px) {
        font-size:12px;
        max-width:370px;
    }

    @media (max-width: 899.98px) {
        font-size:10px;
    }

    @media (max-width: 599.98px) { 
        font-size:10px;
    }
`

const ListItem = ({ title, desc, bullet1, bullet2, bullet3, bullet4, bullet5, text1, text2, text3, text4, text5, text6, text7 }) => {
    return (
        <Grid container flexDirection={'column'}>
            <Grid item>
                <TitleText>{title}</TitleText>
            </Grid>
            <Grid item>
                <Grid container flexDirection={'column'} rowSpacing={2.5}>
                    <Grid item>

                        <ListText>{desc}</ListText>

                    </Grid>
                    <Grid item>
                        <Grid container  columnSpacing={1}>
                            <Grid item lineHeight={1.3}>
                                {bullet1}
                            </Grid>
                            <Grid item>
                                <ListText>{text1}</ListText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container  columnSpacing={1}>
                            <Grid item lineHeight={1.3}>
                                {bullet2}
                            </Grid>
                            <Grid item>
                                <ListText>{text2}</ListText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container  columnSpacing={1}>
                            <Grid item lineHeight={1.3}>
                                {bullet3}
                            </Grid>
                            <Grid item>
                                <ListText>{text3}</ListText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container  columnSpacing={1}>
                            <Grid item lineHeight={1.3}>
                                {bullet4}
                            </Grid>
                            <Grid item>
                                <ListText>{text4}</ListText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container  columnSpacing={1}>
                            <Grid item lineHeight={1.3}>
                                {bullet4}
                            </Grid>
                            <Grid item>
                                <ListText>{text5}</ListText>
                            </Grid>
                        </Grid>
                    </Grid>
                    {text6 && (

                        <Grid item>
                            <Grid container  columnSpacing={1}>
                                <Grid item lineHeight={1.3}>
                                    {bullet4}
                                </Grid>
                                <Grid item>
                                    <ListText>{text6}</ListText>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                </Grid>
            </Grid>
        </Grid>

    )
}

export default ListItem