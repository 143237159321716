import React from 'react'
import { Box, Grid } from '@mui/material'
import { ReactComponent as Logo } from '../Assets/Logo.svg'
import NavLinks from './NavLinks'
import useWindowDimensions from '../hooks/getWindowDimensions';
import HamburgerMenu from './HamburgerMenu';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SignUp = styled.button`
    font-size:15px;
    font-weight:700;
    line-height:21px;
    padding:10px;
    border-radius:150px;
    border:1px solid #3162AC;
    color:#3162AC;
    background-color:#fff;
`

const AplikoNavbar = () => {

    const { width } = useWindowDimensions();

    return (
        <Box width={"100%"} backgroundColor={"#fff"} position={'fixed'} py={2} top={0} left={0} zIndex={1000}>
            {width > 1100 ? (
                <Grid container paddingInline={"200px"} justifyContent={'center'} >
                    <Grid item>
                        <Link to='/'>
                            <Logo />
                        </Link>
                    </Grid>

                </Grid>


            ) : (
                <Grid container paddingInline={"25px"} justifyContent={'center'} alignItems={'center'} style={{ alignItems: 'center' }}>
                    <Link to='/'>
                        <Logo />
                    </Link>
                </Grid>
            )}

        </Box>
    )
}

export default AplikoNavbar