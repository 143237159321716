import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const LabelText = styled.span`
    display:block;
    font-size:16px;
    font-weight:500;
    line-height:24px;
    color:#3F4652;
    padding-bottom:7px;

    @media (max-width: 1440px) {
      font-size:13px;
    }
`

const OptionalText = styled.span`
    font-size:14px;
    font-weight:700;
    line-height:21px;
    color:#3162AC;
    padding-bottom:7px;
    @media (max-width: 1440px) {
      font-size:13px;
    }
`
const Label = ({ text, style, optional }) => {
  return (
    <Grid item>
      <LabelText style={{ ...style }}>{text} <OptionalText>{optional}</OptionalText></LabelText>
    </Grid>
  )
}

export default Label