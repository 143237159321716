import React from 'react'
import styled from 'styled-components'


const D = styled.p`
    font-size:18.87px;
    font-weight:400;
    line-height:29.4px;
    width:100%;
    max-width:510px;
    color:#3F4652;

    @media (max-width: 899.98px) {
        font-size:16px;
        line-height:23px;
        max-width:350px;
    }

    @media (max-width: 599.98px) { 
        font-size:16px;
        line-height:23px;
        max-width:350px;
    }
`

const Desc = ({ desc, style }) => {
    return (
        <D style={{ ...style }}>{desc}</D>
    )
}

export default Desc