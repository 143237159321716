import React from 'react'
import SectionApliko from '../Sections/SectionApliko'
import SectionBenefitet from '../Sections/SectionBenefitet'
import SectionLokacioni from '../Sections/SectionLokacioni'
import SectionHistoria from '../Sections/SectionHistoria'
import { ReactComponent as Topcircle } from '../Assets/Topcircle.svg'
import { ReactComponent as Middlecircle } from '../Assets/Middlecircle.svg'
import SectionJobs from '../Sections/SectionJobs'
import SectionCallingforJob from '../Sections/SectionCallingforJob'
import useWindowDimensions from '../hooks/getWindowDimensions'
import Navbar from '../Components/Navbar'

const LandingPage = () => {

    const { width } = useWindowDimensions();
    return (
        <>
            <Navbar />
            <div style={{ position: "relative" }}>
                {width > 899.99 && (
                    <div style={{ position: "absolute", top: -130, zIndex: -1 }}>
                        <Topcircle style={{ width: "100%", height: "auto" }} />
                    </div>
                )}
                <SectionApliko />
                <SectionBenefitet />
                {width > 899.99 && (
                    <div style={{ position: "absolute", top: 1600, right: 0, zIndex: -1 }}>
                        <Middlecircle style={{ width: "100%", height: "auto" }} />
                    </div>
                )}

                <SectionLokacioni />
                
                <SectionHistoria />
                <SectionJobs />
                <SectionCallingforJob />
            </div>
        </>
    )
}

export default LandingPage