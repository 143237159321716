import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { Grid } from '@mui/material'
import RrethNesh from '../Assets/rrethNesh.png'
import styled from 'styled-components'
import Desc from '../Components/Desc'
import useWindowDimensions from '../hooks/getWindowDimensions'


const T = styled.p`
    color:#3F4652;
    font-size:32px;
    line-height:57.82px;
    font-weight:700;

    @media (max-width: 899.98px) {
        font-size:20px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:18px;
        line-height:18px;
    }
`

const Title = styled.span`
    color:#3F4652;
    font-size:47.47px;
    line-height:60px;
    font-weight:400;

    @media (max-width: 899.98px) {
        font-size:28px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:28px;
        line-height:30px;
    }
`

const GreenText = styled.span`
    color:#3162AC;
    font-size:47.47px;
    line-height:60px;
    font-weight:700;

    @media (max-width: 899.98px) {
        font-size:28px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:28px;
        line-height:30px;
    }
`
const SectionHistoria = () => {


    const { width } = useWindowDimensions();

    return (
        <div id='kush-jemi-ne'>
            <SectionBreaker>
                <Container>
                    <Grid container justifyContent={'center'} alignItems={'center'} columnSpacing={5} paddingInline={width < 899.99 && "25px"}>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item>
                                    <T>Rreth nesh</T>
                                </Grid>
                                <Grid item>
                                    <Title>Historia e <GreenText>Fox Group</GreenText> dhe misioni ynë</Title>
                                </Grid>
                                <Grid item>
                                    <Desc style={{ fontSize: width < 899.99 && '14px', lineHeight: width < 899.99 && '28px', maxWidth: width < 899.99 && '400px' }} desc={'Që nga themelimi ynë, kemi qëndruar të angazhuar për të ofruar shërbime të cilësisë së lartë që i përgjigjen nevojave të ndryshme të klientëve tanë. Në zemër të misionit tonë qëndron inovacioni, integriteti dhe ndërtimi i marrëdhënieve të qëndrueshme. Me një ekip të dedikuar profesionistësh dhe përdorimin e teknologjive më të fundit, ne sigurojmë që çdo klient të përjetojë një lidhje të shpejtë, të sigurt dhe të qëndrueshme.'} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} textAlign={'center'}>
                            {width > 899.99 ? (
                                <img src={RrethNesh} style={{ width: "100%", height: "auto" }} alt="" />
                            ) : (
                                <img src={RrethNesh} style={{ width: "100%", maxWidth: "400px", height: "auto" }} alt="" />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </SectionBreaker>
        </div>
    )
}

export default SectionHistoria