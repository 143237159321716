import React from 'react'
import Container from '../Components/Container'
import SectionBreaker from '../Components/SectionBreaker'
import { ReactComponent as Kosova } from '../Assets/Kosova.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'

const SectionLokacioni = () => {

    const { width } = useWindowDimensions();

    return (
        <SectionBreaker style={{ paddingTop: width > 1535.98 && "250px" }}>
            <Container>
                <Kosova style={{ width: "100%", height: "auto" }} />
            </Container>
        </SectionBreaker >
    )
}

export default SectionLokacioni