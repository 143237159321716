import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Radio = styled.label`
    display:block;    
    width:100%;
    padding:10px 8px;
    border-radius:5px;
    border:${(props) => (props.checked ? "1px solid #3162AC" : "1px solid #DBDBDB")};
    background-color:#fff;
    font-size:14px;
    font-weight:500;
    line-height:18px;
    letter-spacing:0.75px;
    border-radius:8px;
    cursor:pointer;

    @media (max-width: 1440px) {
        font-size:10px;
        padding:7px 5px;
    }

    @media (max-width: 899.98px) {
        font-size:10px;
        padding:15px 5px;
    }

    @media (max-width: 599.98px) { 
        font-size:10px;
        padding:15px 0px;
    }
`

const CustomRadioButton = ({ svg, language, name, value, setData, checked }) => {

    const handleChange = (e) => {
        setData(
            (prev) => ({
                ...prev,
                [name]: e.target.value
            })
        )
        console.log(value);
    }

   
    return (
        <div>
            <Radio checked={checked}>
                <input type='radio' checked={checked} required onChange={handleChange} value={value} name={name} style={{ display: "none" }} />
                <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                    {svg && (
                        <Grid item>
                            {svg}
                        </Grid>
                    )}
                    <Grid item>
                        {language}
                    </Grid>
                </Grid>
            </Radio>
        </div>
    )
}

export default CustomRadioButton