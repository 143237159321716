import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import useWindowDimensions from '../hooks/getWindowDimensions'

const Wrapper = styled.div`
    width:100%;
    max-width:340px;
    padding-inline:40px;
    padding-top:40px;
    padding-bottom:40px;
    border: 1px solid grey;
    border-radius:10px;
    box-shadow: 0px 56px 100px 0px rgba(32, 32, 32, 0.07);
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: rgba(255, 255, 255, 0.15); 
        transform: scale(1.1)
    }

    @media (max-width: 899.98px) {
        width:calc(100vw - 130px);
    }
    @media (max-width: 599.98px) {
        max-width: unset;
    }
`

const Job = styled.span`
    color:#fff;
    font-size:24.8px;
    font-weight:700;
    line-height:27.5px;
    margin-bottom:2px;

    @media (max-width: 899.98px) {
        font-size:18px;
    }

    @media (max-width: 599.98px) { 
        font-size:18px; 
    }
`

// const Button = styled.button`
//     width:100%;
//     padding:15px;
//     color:#3162AC;
//     border-radius:5px;
//     background-color:#fff;
//     border:none;
//     font-size:16px;
//     font-weight:700;
//     line-height:20.93px;
//     cursor:pointer;
// `

const JobsCard = ({ job, svg, desc }) => {

    const { width } = useWindowDimensions()

    return (
        <Wrapper>
            <Grid container alignItems={'center'} columnSpacing={2}>
                <Grid item xs={3}>
                    {svg}
                </Grid>
                <Grid item xs={9}>
                    <Grid container flexDirection={'column'} rowGap={2}>
                        <Grid item>
                            <Job>{job}</Job>
                        </Grid>
                        <Grid item>
                            <Button button={`${desc}`} style={{ fontSize: "16px", maxWidth: "none", paddingTop: width < 899.99 && "20px", paddingBottom: width < 899.99 && "20px" }} color={'#fff'} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default JobsCard