import React, { useState } from 'react'
import AplikoNavbar from '../Components/AplikoNavbar'
import { Grid } from '@mui/material'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import styled from 'styled-components'
import { ReactComponent as Bullet } from '../Assets/ListBullet.svg'
import { ReactComponent as German } from '../Assets/GermanLang.svg'
import { ReactComponent as Italian } from '../Assets/ItalianLang.svg'
import { ReactComponent as English } from '../Assets/EnglishLang.svg'
import { ReactComponent as Restart } from '../Assets/RestartIcon.svg'
import ListItem from '../Components/ListItem'
import Label from '../Components/Label'
import CustomSelect from '../Components/CustomSelect'
import CustomRadioButton from '../Components/CustomRadioButton'
import CustomInput from '../Components/CustomInput'
import CustomCheckbox from '../Components/CustomCheckbox'
import UploadCVButton from '../Components/UploadCVButton'
import useWindowDimensions from '../hooks/getWindowDimensions'
import ControlledAccordion from '../Components/ControlledAccordion'
import axios from 'axios'

const GreenBox = styled(Grid)`
    width:100%;
    background-color:#3162AC;
    padding:50px;
    border-radius:10px;
    position:relative;
    box-shadow: 0px 4px 12px 0px rgba(149, 149, 149, 0.25);
`

const WhiteBox = styled(Grid)`
    background-color:#fff;
    width:100%;
    max-width:850px;
    padding:50px;
    border-radius:10px;
    border: 1px solid #E5E5E5;
    position:absolute;
    top:0;
    right:0px;

    @media (max-width: 1440px) {
        max-width:600px;
        top:-1px;
        right:-1px;
    }
`

const Title = styled.span`
    font-size:26px;
    font-weight:700;
    line-height:39px;
    color:#3162AC;
`

const AplikoButton = styled.button`
    width:100%;
    padding:15px;
    color:#fff;
    border-radius:5px;
    background-color:#3162AC;
    border:none;
    font-size:16px;
    font-weight:700;
    line-height:20.93px;
    cursor:pointer;
`

const SuccessMessage = styled.span`
    display:block;
    padding-top:15px;
    font-weight:600;
    color:#3162AC;
`

const ApplicationPage = () => {

    const [Data, setData] = useState({
        jobPosition: '',
        level: '',
        language: '',
        otherLanguage: '',
        emri: '',
        mbiemri: '',
        numriPersonal: '',
        qyteti: '',
        numriKontaktues: '',
        email: '',
        cv: null,
        kushteteperdorimit: false
    });

    const [SuccessfulMessage, setSuccessfulMessage] = useState()

    const Joboptions = [
        { value: '', label: 'Pozita e punës' },
        { value: 'Call Agent', label: 'Call Agent' },
        { value: 'Quality Agent', label: 'Quality Agent' }
    ];

    const Langoptions = [
        { value: '', label: 'Gjuhë të tjera' },
        { value: 'Spanjolle', label: 'Spanjolle' },
        { value: 'Portugeze', label: 'Portugeze' }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        var Formdata = new FormData();
        Object.entries(Data).forEach(([key, value]) => {
            Formdata.append(key, value);
        });
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        await axios.post("https://email.kutiza.com/send-email", Formdata, config).then((res) => {
         console.log(res);
		 setSuccessfulMessage("Aplikimi u krye me sukses!");
        })


        // axios.post("http://192.168.10.106:3001/send-email", data)
        // .then(response => {
        //     console.log('Response:', response.data);
        //     setSuccessfulMessage("Aplikimi u krye me sukses!");
        // })
        // .catch(error => {
        //     if (error.response) {
        //         console.error('Server Error:', error.response.data);
        //         console.error('Status Code:', error.response.status);
        //         console.error('Headers:', error.response.headers);
        //     } else if (error.request) {
        //         console.error('No response received:', error.request);
        //     } else {
        //         console.error('Error:', error.message);
        //     }
        //     console.error('Error config:', error.config);
        // });
    };

    const { width } = useWindowDimensions();

    const [accordion1Expanded, setAccordion1Expanded] = useState(false);
    const [accordion2Expanded, setAccordion2Expanded] = useState(false);

    const handle1AccordionChange = (expanded) => {
        setAccordion1Expanded(expanded);
        setAccordion2Expanded(false)
    };

    const handle2AccordionChange = (expanded) => {
        setAccordion1Expanded(false);
        setAccordion2Expanded(expanded)
    };

    const RestartData = () => {
        setData({
            emri: '',
            mbiemri: '',
            numriPersonal: '',
            qyteti: '',
            numriKontaktues: '',
            email: ''
        });
    };

    return (
        <>
            <AplikoNavbar />
            <div style={{ paddingTop: width <= 1440 && width >= 899.99 && "100px" }}>
                <SectionBreaker>
                    {width > 1200 ? (
                        <Container style={{ maxWidth: width > 1440 ? "1430px" : "1200px" }} >
                            <GreenBox container gap={8}>
                                <Grid item xs={12}>
                                    <ListItem
                                        title={'Çfare presim nga ju?'}
                                        desc={`Për t'u bërë pjesë e ekipit tonë, kërkojmë që kandidatët të përmbushin disa kërkesa kyçe, të cilat përfshijnë:`}
                                        bullet1={<Bullet />}
                                        bullet2={<Bullet />}
                                        bullet3={<Bullet />}
                                        bullet4={<Bullet />}
                                        text1={'Njohuri të një prej gjuhëve të kërkuara: Të keni aftësi të forta në një ose më shumë prej gjuhëve të specifikuara në përshkrimin e punës.'}
                                        text2={'Fleksibilitet në orarin e punës: Të jeni të gatshëm për të punuar në orare të ndryshme, në varësi të kërkesave të punës.'}
                                        text3={`Aftësi për t'u përshtatur: Të keni aftësi të larta për të punuar në një mjedis dinamik dhe të ndryshueshëm.`}
                                        text4={`Bashkëpunues dhe komunikues: Të jeni të aftë të punoni në ekip, duke mbajtur një qasje të hapur dhe bashkëpunuese.`}
                                        text5={`Përkushtim dhe përpikëri: Të tregoni përkushtim në arritjen e objektivave dhe përpikëri në përmbushjen e detyrave brenda afateve të caktuara.`}
                                        text6={`Motivim dhe dëshirë për zhvillim: Të jeni të motivuar për të mësuar dhe për të zhvilluar aftësitë tuaja në vazhdimësi.`} />
                                </Grid>
                                <Grid item xs>
                                    <ListItem
                                        title={'Kualifikimet e kerkuara'}
                                        bullet1={<Bullet />}
                                        bullet2={<Bullet />}
                                        bullet3={<Bullet />}
                                        bullet4={<Bullet />}
                                        bullet5={<Bullet />}
                                        text1={'Njohja e njërës ose më shumë prej gjuhëve të kërkuara: Aftësi të shkëlqyera në të folur dhe të shkruar në një ose më shumë prej gjuhëve të specifikuara në përshkrimin e punës.'}
                                        text2={'Aftësi të mira komunikuese: Aftësi për të komunikuar qartë dhe në mënyrë efektive, si me kolegët ashtu edhe me klientët.'}
                                        text3={'Eksperienca e mëparshme: Preferohet të keni përvojë në pozita të ngjashme, por nuk është e detyrueshme nëse tregoni dëshirë për të mësuar.'}
                                        text4={'Aftësi për të përdorur teknologjinë: Njohuri të mira në përdorimin e programeve bazë të kompjuterit dhe aplikacioneve të nevojshme për punën.'}
                                        text5={'Aftësi për të punuar në mënyrë të pavarur dhe në ekip: Të tregoni aftësi për të marrë përgjegjësi individuale dhe për të kontribuar në mënyrë efektive në mjediset bashkëpunuese.'} />
                                </Grid>
                                <Grid item>
                                    <WhiteBox >
                                        <form onSubmit={handleSubmit}>
                                            <Grid item xs={12} paddingBottom={'70px'}>
                                                <Title>Plotëso Aplikimin</Title>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container rowSpacing={4}>
                                                    <Grid item xs={12}>
                                                        <Label text={'Pozita për të cilen po aplikoni'} />
                                                        <CustomSelect options={Joboptions} setData={setData} name={'jobPosition'} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Label text={'Cilën gjuhë flisni?'} />
                                                        <Grid item>
                                                            <Grid container columnSpacing={3.5}>
                                                                <Grid item xs={3.9}>
                                                                    <CustomRadioButton checked={Data.language === 'Gjermane'} setData={setData} name={'language'} value={'Gjermane'} svg={<German />} language={'Gjermane'} />
                                                                </Grid>
                                                                <Grid item xs={3.9}>
                                                                    <CustomRadioButton checked={Data.language === 'Italiane'} setData={setData} name={'language'} value={'Italiane'} svg={<Italian />} language={'Italiane'} />
                                                                </Grid>
                                                                <Grid item xs={3.9}>
                                                                    <CustomRadioButton checked={Data.language === 'Frënge'} setData={setData} name={'language'} value={'Frënge'} svg={<English />} language={'Frënge'} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <Label text={'Niveli i Gjuhës'} />
                                                        <Grid container columnGap={width <= 1440 ? 2 : 3}>
                                                            <Grid item >
                                                                <CustomRadioButton checked={Data.level === 'A1'} setData={setData} name={'level'} value={'A1'} language={'A1'} />
                                                            </Grid>
                                                            <Grid item >
                                                                <CustomRadioButton checked={Data.level === 'A2'} setData={setData} name={'level'} value={'A2'} language={'A2'} />
                                                            </Grid>
                                                            <Grid item >
                                                                <CustomRadioButton checked={Data.level === 'B1'} setData={setData} name={'level'} value={'B1'} language={'B1'} />
                                                            </Grid>
                                                            <Grid item >
                                                                <CustomRadioButton checked={Data.level === 'B2'} setData={setData} name={'level'} value={'B2'} language={'B2'} />
                                                            </Grid>
                                                            <Grid item>
                                                                <CustomRadioButton checked={Data.level === 'C1'} setData={setData} name={'level'} value={'C1'} language={'C1'} />
                                                            </Grid>
                                                            <Grid item >
                                                                <CustomRadioButton checked={Data.level === 'C2'} setData={setData} name={'level'} value={'C2'} language={'C2'} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Label text={'A flisni gjuhë të tjera?'} optional={'(opsionale)'} />
                                                        <CustomSelect options={Langoptions} setData={setData} name={'otherLanguage'} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container alignItems={'center'}>
                                                            <Grid item xs={6}>
                                                                <Label text={'Të dhënat personale'} />
                                                            </Grid>
                                                            <Grid item xs={6} textAlign={'right'}>
                                                                <div onClick={RestartData} style={{ cursor: "pointer" }}>
                                                                    <Restart />
                                                                </div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container gridColumn={2} columnSpacing={1} rowGap={2}>
                                                            <Grid item xs={6} >
                                                                <CustomInput type={'text'} value={Data.emri} setData={setData} name={'emri'} placeholder={'Emri'} />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <CustomInput type={'text'} value={Data.mbiemri} setData={setData} name={'mbiemri'} placeholder={'Mbiemri'} />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <CustomInput type={'text'} value={Data.numriPersonal} minLength={'11'} maxLength={'11'} setData={setData} name={'numriPersonal'} placeholder={'Numri Personal'} />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <CustomInput type={'text'} value={Data.qyteti} setData={setData} name={'qyteti'} placeholder={'Qyteti'} />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <CustomInput type={'phone'} value={Data.numriKontaktues} minLength={'9'} maxLength={'9'} setData={setData} name={'numriKontaktues'} placeholder={'Numri Kontaktues'} />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <CustomInput type={'email'} value={Data.email} setData={setData} name={'email'} placeholder={'E-mail'} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Label text={'Dergo CV'} />
                                                        <UploadCVButton setData={setData} name={'cv'} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <hr style={{ border: "1px solid #EBEFF3", width: "100%" }}></hr>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CustomCheckbox name={'kushteteperdorimit'} setData={setData} label={'Une pajtohem me kushtet e shërbimit'} />
                                                    </Grid>
                                                    <Grid container style={{ paddingTop: "50px" }}>
                                                        <AplikoButton type='submit'>Dërgo Aplikimin</AplikoButton>
                                                        <Grid item container justifyContent={'center'}>
                                                            <SuccessMessage>{SuccessfulMessage}</SuccessMessage>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </WhiteBox>
                                </Grid>
                            </GreenBox>
                        </Container >
                    ) : (
                        <Container>
                            <form onSubmit={handleSubmit}>
                                <Grid container rowGap={5} paddingInline={"35px"}>
                                    <Grid item xs={12}>
                                        <ControlledAccordion text={'Çfarë presim nga ju?'}
                                            expanded={accordion1Expanded}
                                            onChange={handle1AccordionChange}
                                            text1={'Njohuri të një prej gjuhëve të kërkuara: Të keni aftësi të forta në një ose më shumë prej gjuhëve të specifikuara në përshkrimin e punës.'}
                                            text2={'Fleksibilitet në orarin e punës: Të jeni të gatshëm për të punuar në orare të ndryshme, në varësi të kërkesave të punës.'}
                                            text3={`Aftësi për t'u përshtatur: Të keni aftësi të larta për të punuar në një mjedis dinamik dhe të ndryshueshëm.`}
                                            text4={`Bashkëpunues dhe komunikues: Të jeni të aftë të punoni në ekip, duke mbajtur një qasje të hapur dhe bashkëpunuese.`}
                                            text5={`Përkushtim dhe përpikëri: Të tregoni përkushtim në arritjen e objektivave dhe përpikëri në përmbushjen e detyrave brenda afateve të caktuara.`}
                                            text6={`Motivim dhe dëshirë për zhvillim: Të jeni të motivuar për të mësuar dhe për të zhvilluar aftësitë tuaja në vazhdimësi.`} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ControlledAccordion text={'Kualifikimet e kërkuara'}
                                            expanded={accordion2Expanded}
                                            onChange={handle2AccordionChange}
                                            text1={'Njohja e njërës ose më shumë prej gjuhëve të kërkuara: Aftësi të shkëlqyera në të folur dhe të shkruar në një ose më shumë prej gjuhëve të specifikuara në përshkrimin e punës.'}
                                            text2={'Aftësi të mira komunikuese: Aftësi për të komunikuar qartë dhe në mënyrë efektive, si me kolegët ashtu edhe me klientët.'}
                                            text3={'Eksperienca e mëparshme: Preferohet të keni përvojë në pozita të ngjashme, por nuk është e detyrueshme nëse tregoni dëshirë për të mësuar.'}
                                            text4={'Aftësi për të përdorur teknologjinë: Njohuri të mira në përdorimin e programeve bazë të kompjuterit dhe aplikacioneve të nevojshme për punën.'}
                                            text5={'Aftësi për të punuar në mënyrë të pavarur dhe në ekip: Të tregoni aftësi për të marrë përgjegjësi individuale dhe për të kontribuar në mënyrë efektive në mjediset bashkëpunuese.'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container rowGap={3}>
                                            <Grid item xs={12}>
                                                <Label text={'Pozita për të cilen po aplikoni'} />
                                                <CustomSelect options={Joboptions} setData={setData} name={'jobPosition'} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Label text={'Cilen gjuhe e flisni?'} />
                                                <Grid container columnSpacing={2}>
                                                    <Grid item xs={4}>
                                                        <CustomRadioButton checked={Data.language === 'Gjermane'} setData={setData} name={'language'} value={'Gjermane'} svg={<German />} language={'Gjermane'} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <CustomRadioButton checked={Data.language === 'Frënge'} setData={setData} name={'language'} value={'Frënge'} svg={<English />} language={'Frënge'} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <CustomRadioButton checked={Data.language === 'Italiane'} setData={setData} name={'language'} value={'Italiane'} svg={<Italian />} language={'Italiane'} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Label text={'Niveli i Gjuhës'} />
                                                <Grid container columnSpacing={2}>
                                                    <Grid item xs={2}>
                                                        <CustomRadioButton checked={Data.level === 'A1'} setData={setData} name={'level'} value={'A1'} language={'A1'} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <CustomRadioButton checked={Data.level === 'A2'} setData={setData} name={'level'} value={'A2'} language={'A2'} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <CustomRadioButton checked={Data.level === 'B1'} setData={setData} name={'level'} value={'B1'} language={'B1'} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <CustomRadioButton checked={Data.level === 'B2'} setData={setData} name={'level'} value={'B2'} language={'B2'} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <CustomRadioButton checked={Data.level === 'C1'} setData={setData} name={'level'} value={'C1'} language={'C1'} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <CustomRadioButton checked={Data.level === 'C2'} setData={setData} name={'level'} value={'C2'} language={'C2'} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Label text={'A flisni gjuhe tjera?'} />
                                                <CustomSelect options={Langoptions} setData={setData} name={'jobPosition'} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Label text={'Të dhënat personale'} />
                                                <Grid container columnGap={2} rowGap={1}>
                                                    <Grid item xs={12} >
                                                        <CustomInput type={'text'} setData={setData} name={'emri'} placeholder={'Emri'} />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <CustomInput type={'text'} setData={setData} name={'mbiemri'} placeholder={'Mbiemri'} />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <CustomInput type={'text'} minLength={'11'} maxLength={'11'} setData={setData} name={'numriPersonal'} placeholder={'Numri Personal'} />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <CustomInput type={'text'} setData={setData} name={'qyteti'} placeholder={'Qyteti'} />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <CustomInput type={'phone'} minLength={'9'} maxLength={'9'} setData={setData} name={'numriKontaktues'} placeholder={'Numri Kontaktues'} />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <CustomInput type={'email'} setData={setData} name={'email'} placeholder={'E-mail'} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Label text={'Dergo CV'} />
                                                <UploadCVButton setData={setData} name={'cv'} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomCheckbox name={'kushteteperdorimit'} setData={setData} label={'Une pajtohem me kushtet e shërbimit'} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AplikoButton type='submit'>Dërgo Aplikimin</AplikoButton>
                                                <Grid item container justifyContent={'center'}>
                                                    <SuccessMessage>{SuccessfulMessage}</SuccessMessage>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    )
                    }
                </SectionBreaker >
            </div >
        </>
    )
}

export default ApplicationPage