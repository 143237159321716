import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LandingPage from './Pages/LandingPage';
import Footer from './Components/Footer';
import ApplicationPage from './Pages/ApplicationPage';

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/apliko' element={<ApplicationPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
