import { Grid } from '@mui/material'
import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { ReactComponent as Icons } from '../Assets/CallingforJobIcons.svg'
import styled from 'styled-components'
import Desc from '../Components/Desc'
import Button from '../Components/Button'
import useWindowDimensions from '../hooks/getWindowDimensions'
import Thought from '../Assets/Thought.svg'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
    border-radius:20px;
    diplay:flex;
    justify-content:center;
    padding:30px;
    background-color:#3162AC;
`

const WrapperImage = styled.div`
    display:flex;
    justify-content:center;
    align-items: center;
    background: url(${Thought}) no-repeat;
`

const Title = styled.div`
    font-size:40px;
    font-weight:700;
    line-height:52px;
    color:#fff;
    width:100%;
    max-width:300px;

    @media (max-width: 1440px) {
        font-size:43px;
        line-height:50px;
    }

    @media (max-width: 899.98px) {
        font-size:33px;
        line-height:40px;
    }

    @media (max-width: 599.98px) { 
        font-size:33px;
        line-height:40px;
    }
`

const DescImage = styled.span`
    display:block;
    color:#fff;
    font-size:18.87px;
    width:100%;
    max-width:400px;
`
const SectionCallingforJob = () => {

    const { width } = useWindowDimensions();

    return (
        <SectionBreaker>
            <Container>
                <Grid container alignItems={'center'} flexDirection={width < 899.99 && "column-reverse"} rowGap={10} paddingInline={width < 899.99 && "25px"}>
                    <Grid item xs={12} md={6} textAlign={'center'}>
                        {
                            width > 899.99 ? (
                                <Icons style={{ width: "100%", height: "auto", maxWidth: "450px" }} />
                            ) : (
                                <Icons style={{ width: "100%", maxWidth: "350px", height: "auto" }} />
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6} textAlign={'left'}>
                        {
                            width > 1309.99 ? (
                                <WrapperImage style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: width > 899.99 ? "400px" : "25px", paddingInline: '75px' }}>

                                    <Grid container justifyContent={'center'} alignItems={'center'} rowGap={3} columnGap={0}>
                                        <Grid item xs={12}>
                                            <Title>Mundësia po të thërret</Title>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DescImage>Na kontakto tani për çdo informatë rreth kompanisë tonë dhe dikush nga ekipi ynë do të kthehet tek ju.</DescImage>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Link to={'/apliko'}>
                                                <Button button={'Apliko'} style={{ maxWidth: "300px", backgroundColor: "#fff", borderRadius: "52px" }} color={"#3162AC"} />
                                            </Link>
                                        </Grid>
                                    </Grid>

                                </WrapperImage>
                            ) : (
                                <Wrapper>
                                    <Grid container flexDirection={'column'} justifyContent={'center'}>
                                        <Grid item xs={12}>
                                            <Title>Mundesia po te</Title>
                                            <Title>therret</Title>
                                        </Grid>
                                        <Grid item xs>
                                            <Desc style={{ color: "#fff", textAlign: "left", maxWidth: width < 899.99 ? "300px" : "400px", fontSize: width < 899 && "11.5px" }} desc={'Na kontakto tani për çdo informatë rreth kompanisë tonë dhe dikush nga ekipi ynë do të kthehet tek ju'} />
                                        </Grid>
                                        <Grid item xs>
                                            <Link to={'/apliko'}>


                                                <Button style={{ color: "#3162AC", backgroundColor: "#fff" }} button={'Apliko'} color={'#3162AC'} />

                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Wrapper>
                            )}
                    </Grid>
                </Grid>
            </Container>
        </SectionBreaker >
    )
}

export default SectionCallingforJob