import React from 'react'
import SectionBreaker from './SectionBreaker'
import { Container, Grid } from '@mui/material'
import styled from 'styled-components'
import LocationPhoneNumbers from './LocationPhoneNumbers'
import useWindowDimensions from '../hooks/getWindowDimensions'
import { ReactComponent as Fb } from '../Assets/facebook.svg'
import { ReactComponent as Ig } from '../Assets/intsgram.svg'
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const HelloText = styled.p`
    color:#fff;
    font-size:21.48px;
    font-weight:700;
    line-height:27.5px;
    margin-bottom:15px;
`

const CopyRight = styled.p`
    color:#fff;
    font-size:12px;
    font-weight:400;
    line-height:21px;
`
const T = styled.p`
    color:#fff;
    font-size:18px;
    font-weight:700;
    line-height:27.5px;
`

const L = styled.span`
    color:#fff;
    font-size:14px;
    font-weight:400;
    line-height:25.2px;
`

const Footer = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const { width } = useWindowDimensions();

    return (
        <SectionBreaker>
            <div style={{ background: "#3162AC" }}>
                <Container>
                    <Grid container py={3} justifyContent={'center'} paddingInline={width < 899.99 && "25px"} >
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container flexDirection={'column'}>
                                        <Grid item>
                                            <T>Rreth nesh</T>
                                        </Grid>
                                        <Grid item>
                                            <Grid container flexDirection={'column'} rowSpacing={1}>
                                                <Grid item>
                                                    <a href="#kush-jemi-ne" style={{ textDecoration: 'none' }}>
                                                        <L>
                                                            Kush jemi ne
                                                        </L>
                                                    </a>
                                                </Grid>
                                                <Grid item>

                                                    <a href="#vende-te-lira-pune" style={{ textDecoration: 'none' }}>
                                                        <L>
                                                            Pozitat e hapura
                                                        </L>
                                                    </a>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>

                                    <Grid container flexDirection={'column'}>
                                        <Grid item>
                                            <T>Ndihmë</T>
                                        </Grid>
                                        <Grid item>
                                            <Grid container flexDirection={'column'} rowSpacing={1}>
                                                <Grid item>
                                                    <Link to={'/apliko'} style={{textDecoration: 'none'}}>
                                                        <L>
                                                            Kontakti
                                                        </L>
                                                    </Link>

                                                </Grid>
                                                {/* <Grid item>
                                                    <L>
                                                        Politkat e privatësisë
                                                    </L>
                                                </Grid> */}

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container flexDirection={'column'}>
                                <Grid item xs={12}>
                                    <HelloText>Lokacionet</HelloText>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2} >
                                        <Grid item xs={4} >
                                            <LocationPhoneNumbers city={`Prishtinë`} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <LocationPhoneNumbers city={`Mitrovicë`} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <LocationPhoneNumbers city={`Gjakovë`} />
                                        </Grid>
                                    </Grid>
                                    <Grid container rowSpacing={2} pt={1}>
                                        <Grid item xs={4} >
                                            <LocationPhoneNumbers city={`Pejë`} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <LocationPhoneNumbers city={`Istog`} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <LocationPhoneNumbers city={`Klinë`} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'} pt={3}>
                        <Grid container columnGap={2} justifyContent={'center'}>
                            <Grid item>
                                <a target='_blank' rel='noreferrer' href="https://facebook.com/foxgroupks">
                                    <Fb />
                                </a>
                            </Grid>
                            <Grid item>
                                <a target='_blank' rel='noreferrer' href="https://instagram.com/foxgroupks">
                                    <Ig />

                                </a>
                            </Grid>
                        </Grid>
                        <CopyRight>Copyright © 2024 Fox Group</CopyRight>
                    </Grid>
                </Container>
            </div>
        </SectionBreaker >
    )
}

export default Footer